import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/shared/services/auth.service";
import { CoreService } from "src/app/shared/services/core.service";

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"],
})
export class VerifyEmailComponent implements OnInit {
  isVerified = false;
  message = "";

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((param) => {
      if (param && param.token) {
        this.verifyEmail(param.token);
      }
    });
  }

  verifyEmail(token: string) {
    this.authService.verifyEmail(token).subscribe((resp) => {
      if (resp.success == 1) {
        this.isVerified = true;
        this.message = resp.data.message;
      } else {
        this.message = resp.error.message;
      }
    });
  }
}
