import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { LoginAuthGuard } from '../shared/guards/login_auth-guard';

const routes: Routes = [
  { path: "", component: LoginComponent, pathMatch: "full", canActivate: [LoginAuthGuard] },
  { path: "login", component: LoginComponent, canActivate: [LoginAuthGuard] },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  {
    path: "verify-email",
    component: VerifyEmailComponent,
    data: { title: "Verify Email" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
