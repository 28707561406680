import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// Import your library
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AuthModule } from "./auth/auth.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CookieService } from "ngx-cookie-service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorInterceptor } from "./shared/helpers/error.interceptor";
import { LoaderInterceptor } from "./shared/helpers/loader.interceptor";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { PushNotificationService } from './shared/services/push-notification.service';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ScaffoldModule } from "./admin/scaffold/scaffold.module";
// import { AgmCoreModule } from "@agm/core";
import { NgxMaskModule } from 'ngx-mask'


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AuthModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      newestOnTop: true,
      autoDismiss: true,
    }),
    // AgmCoreModule.forRoot({
    //   apiKey: "AIzaSyBXNXYsEEIrvFoQOgasLuHGzrcjlHNJIDs",
    //   libraries: ["places"],
    // }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    ScaffoldModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    CookieService,
    PushNotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
