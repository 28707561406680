<!-- <ng-container *ngIf="isLoading | async">
  <app-loader></app-loader>
</ng-container> -->

<div class="loader-container" *ngIf="isLoading">
  <div class="loader-logo">
    <div class="spinner">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
  </div>
</div>

<!-- <main [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
  <router-outlet #o="outlet"></router-outlet>
</main> -->

<router-outlet></router-outlet>
