import { ApplicationRef, ChangeDetectorRef, Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { CoreService } from "./core.service";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private coreService: CoreService,
    private cookieService: CookieService,
    private toastrService: ToastrService,
    private cd: ApplicationRef,
    private notificationService: NotificationService
  ) {
    // this.angularFireMessaging.messaging((_messaging) => {
    //   _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //   _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    // });
    this.angularFireMessaging.messaging.subscribe((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // this.coreService.setDeviceToken(token);
        this.coreService.deviceId = token;
        this.cookieService.set("device_id", token, 1, "/", "", false, "Strict");
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      const msg = payload.data.message;
      this.currentMessage.next(payload);
      if (payload.data) {
        this.toastrService.info(payload.data.message, payload.data.title);
      }
    });
  }
}
