import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private apiService: ApiService) {}

  getUsers(data): Observable<any> {
    return this.apiService.post("/notifications/getUsers", data);
  }

  list(data): Observable<any> {
    return this.apiService.post("/customer/list", data);
  }

  getDrivers(data): Observable<any> {
    return this.apiService.post("/notifications/getDrivers", data);
  }

  getSentNotifications(data): Observable<any> {
    return this.apiService.post("/notifications/sent", data);
  }

  SendNotification(data): Observable<any> {
    return this.apiService.post("/notifications", data);
  }

  getrecivedNotifications(data): Observable<any> {
    return this.apiService.post("/notifications/recive", data);
  }

  removeNotification(data): Observable<any> {
    return this.apiService.post("/notifications/delete", data);
  }

  markasReadUnread(data): Observable<any> {
    return this.apiService.post("/notifications/readunread", data);
  }

  readNotification(data): Observable<any> {
    return this.apiService.post("/notifications/read", data);
  }

  getBellNotifications(): Observable<any> {
    return this.apiService.get("/notifications/getBellNotifications");
  }

  getAllVendorNotification(data: any): Observable<any> {
    return this.apiService.post("/notifications/getVendors", data);
  }

  getAllUser(): Observable<any> {
    return this.apiService.get("/notificationgroup/alluserlist");
  }

  Grouplist(data): Observable<any> {
    return this.apiService.post("/notificationgroup/grouplist", data);
  }
  dropDownGroupList(): Observable<any> {
    return this.apiService.get("/notificationgroup/getgrouplist");
  }

  createGroup(data): Observable<any> {
    return this.apiService.post("/notificationgroup/addgroup", data);
  }

  getGroupById(data): Observable<any> {
    return this.apiService.post("/notificationgroup/groupById", data);
  }

  updateGroup(data): Observable<any> {
    return this.apiService.post("/notificationgroup/editgroup", data);
  }

  deleteGroup(data): Observable<any> {
    return this.apiService.post(`/notificationgroup/remove`, data);
  }
}
