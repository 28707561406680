import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { CoreService } from "src/app/shared/services/core.service";

@Component({
  selector: "app-scaffold",
  templateUrl: "./scaffold.component.html",
  styleUrls: ["./scaffold.component.scss"],
})
export class ScaffoldComponent implements OnInit {
  currentUser$: Observable<any>;

  constructor(private coreService: CoreService) {
    this.currentUser$ = this.coreService.currentUser;
  }

  ngOnInit() {}
}
