import { Component, OnInit, Input } from "@angular/core";
import { CoreService } from "src/app/shared/services/core.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, ReplaySubject } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() user?: any;

  darkScrollbarOptions = {
    theme: "minimal-dark",
    scrollInertia: 100,
    mouseWheel: {
      preventDefault: true,
    },
  };

  isSearchOpen: Boolean = false;
  notificationList: any[] = [];
  count = 0;
  hash = '';

  Users;
  permissions: any = {};
  isheaderShown: any = '' ; // hidden by default

  constructor(
    private coreService: CoreService,
    private authService: AuthService,
    private cookieService: CookieService,
    private notificationService: NotificationService
  ) {
    
    this.coreService.currentUser.subscribe((users) => {
      this.Users = users;
    });
    this.coreService.permission.subscribe((res: any) => {
      res.map((val) => {
        if (val.sub_module == "Notifications") {
          this.permissions[val.action] = val.action;
        }
      });
    });
  }

  ngOnInit() {
    this.getNotificationList();
  }

  showSearchBar() {
    this.isSearchOpen = this.isSearchOpen ? false : true;
  }

  getNotificationList() {
    // console.log('header');
    this.coreService.notificationLists.subscribe((resp) => {
      this.notificationList = resp.notificationList;
      this.count = resp.count;
      
    });
  }

  readNotification(id) {
    this.notificationService.readNotification({ id }).subscribe((response) => {
      if (response.success == 1) {
        this.getNotificationList();
      }
    });
  }

  showHeader() {
    var element = document.getElementById("showSidebar");
    this.isheaderShown = this.isheaderShown?false:true;
    if(this.isheaderShown)
    element.classList.remove('mobile-topbar-toggle');
    else
    element.classList.add('mobile-topbar-toggle');
  }
  logout() {
    this.authService.logout().subscribe((resp) => {
      if (resp.success == 1) {
        this.coreService.purgeAuth();
        localStorage.clear();
        this.cookieService.delete("admin", "/", "", false, "Lax");
        this.cookieService.delete("admin", "/admin", "", false, "Lax");
        this.cookieService.delete("accessToken", "/", "", false, "Lax");
        this.cookieService.delete("accessToken", "/admin", "", false, "Lax");
        this.coreService.navigateTo("/auth/login");
      } else {
        // this.coreService.showError(resp.error.message);
      }
    });
  }
}
