import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { CoreService } from "../services/core.service";
import { CookieService } from "ngx-cookie-service";
import { Actors } from "../constants";

@Injectable({ providedIn: "root" })
export class LoginAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private coreService: CoreService,
    private cookieService: CookieService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentuser;
    let currentUser = this.coreService.currentUser.subscribe(
      (users) => (currentuser = users)
    );
    currentUser.unsubscribe();
    const token = this.cookieService.check("accessToken");
    const user = this.cookieService.check("admin");
    if (token && user) {
      if (currentuser.actor == Actors.Admin) {
        this.router.navigate(["/admin/dashboard"]);
        return false;
      }
      //else {
      //   this.router.navigate(["/auth/login"]);
      //   return false;
      // }
      else if (currentuser.actor == Actors.Vendor) {
        this.router.navigate(["/vendor/dashboard"]);
        return false;
      }
    }
    return true;
  }
}
