<div class="main-container d-flex align-items-center justify-content-center">
  <div class="login-box">
    <div class="login-logo">
      <img src="assets/img/logo.png" class="" alt="Logo" />
    </div>

    <div class="login-box-body">
      <h1 class="text-center font-weight-600 mb-3">Admin Login</h1>
      <p class="text-center mb-4">
        Hi! Please enter your login information below to access the panel.
      </p>
      <app-message></app-message>
      <form [formGroup]="loginForm" (submit)="onSubmit()">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Email ID"
            [formControl]="email"
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Password"
            [formControl]="password"
          />
        </div>
        <button type="submit" class="btn btn-primary btn-block btn-lg">
          <!-- routerLink="/admin/dashboard" -->
          Login
        </button>
      </form>
    </div>
    <div class="login-box-footer clearfix">
      <div class="password-reset-link float-right">
        <a class="text-primary" routerLink="/forgot-password">
          Forgot Password?</a
        >
      </div>
      <div class="form-group float-left mb-0">
        <div class="custom-control custom-checkbox checkbox-primary mb-0">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customCheck2"
            [formControl]="remember"
            [checked]="isChecked"
          />
          <label class="custom-control-label" for="customCheck2"
            >Remember Me</label
          >
        </div>
      </div>
    </div>
  </div>
</div>
