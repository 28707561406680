export const VALIDATION_MESSAGES_EN = {
  email: {
    required: `Please enter email.`,
    email: `Please enter valid email.`,
  },
  password: {
    required: `Please enter password.`,
    pattern: `Password must contains at least 6 characters.`,
    minlength: `Password must be 6 characters long.`,
    maxlength: `Password contain maximum 20 Characters.`,
  },
  confirmpassword: {
    required: `Please enter confirm password.`,
    validatePassword: `Your Password is not matched.`,
    minlength: `Password contain minimum 6 Characters.`,
    maxlength: `Password contain maximum 20 Characters.`,
  },
  old_password: {
    required: `Please enter old password.`,
    minlength: `Password contain minimum 6 Characters.`,
    maxlength: `Password contain maximum 20 Characters.`,
  },
  new_password: {
    required: `Please enter new password.`,
    minlength: `Password contain minimum 6 Characters`,
    maxlength: `Password contain maximum 20 Characters.`,
  },
  confirm_password: {
    required: `Retype new password.`,
    validatePassword: `Your password is not matched`,
    minlength: `Password contain minimum 6 Characters`,
    maxlength: `Password contain maximum 20 Characters.`,
    notMatch: "New password and confirm password should be same.",
  },
  image: {
    required: `Please upload image.`,
  },
  city: {
    required: `City is required.`,
  },
  state: {
    required: `State is required.`,
  },
  country: {
    required: `Country is required.`,
  },
  page_size: {
    required: `Pagesize is required.`,
    maxlength: `PageSize can't be more then 2 digits.`,
    min: `Page limit value should be 5 or more.`,
  },
  users: {
    required: `Please select users.`,
  },
  notify: {
    required: `Please select who will be notify.`,
  },
  reason: {
    maxlength: `Reason can be 150 characters long.`,
  },
  city_name: {
    required: `Please enter City Name.`,
  },
  business_name: {
    required: `Please enter Business Name.`,
  },
  phone_number: {
    required: `Phone number is required.`,
    minlength: `Phone number contain minimum 10 digits.`,
    maxlength: `Phone number contain maximum 10 digits.`,
    pattern: `Phone number should be numeric value.`,
  },
  phone_number_two: {
    minlength: `Phone number contain minimum 10 digits.`,
    maxlength: `Phone number contain maximum 10 digits.`,
    pattern: `Phone number should be numeric value.`,
  },
  position: {
    required: "Please enter position/title.",
  },
  address: {
    required: `Street is required.`,
  },
  street_name: {
    required: `Street is required.`,
  },
  title: {
    required: `Please enter CMS Particular Name.`,
  },
  content: {
    required: `Please enter CMS Content.`,
  },
  question: {
    required: `Please enter question`,
  },
  answer: {
    required: `Please enter answer`,
  },
  vendor_delivery_charge: {
    required: `Please enter Vendor Delivery Charge`,
  },
  stock_alert: {
    required: `Please enter Stock Alert`,
  },
  transportation: {
    required: `Please enter Transportation`,
  },
  union_fee: {
    required: `Please enter Union Fee`,
  },
  account_name: {
    required: `Please enter Account Name`,
    maxlength: `Accound name contain maximum 100 Char.`,
  },
  bank_name: {
    required: `Please enter Bank Name`,
    maxlength: `bank name contain maximum 100 Char.`,
  },
  nick_name: {
    required: `Please enter bank nick Name`,
    maxlength: `bank name contain maximum 100 Char.`,
  },
  account_number: {
    required: `Please enter Account Number`,
    maxlength: `Account number must contain 10 digits.`,
    minlength: `Account number must contain 10 digits.`,
  },
  state_id: {
    required: `Please select state.`,
  },
  local_govt_id: {
    required: `Please select local government.`,
  },
  make_id: {
    required: `Please select vehicle make.`,
  },

  model_id: {
    required: `Please select vehicle model.`,
  },

  year: {
    required: `Please enter registration year.`,
  },

  color: {
    required: `Please enter vehicle color.`,
    maxlength: `Color contain maximum 20 char.`,
  },
  capacity: {
    required: `Please enter vehicle capaity.`,
    maxlength: `Capacity contain maximum 11 char.`,
    pattern: `Only numeric value is allowed`,
  },
  truck_capacity: {
    required: `Please enter vehicle capaity.`,
    max: `Truck Capacity should not grater than 150 MT.`,
    min: `Truck Capacity should not 0 MT.`,
    pattern: `Only numeric value is allowed`,
  },
  contact_person: {
    required: `Please enter contact person.`,
  },
  contact_person_title: {
    required: `Please enter contact person position/title.`,
  },
  contact_person_email: {
    required: `Please enter email.`,
    email: `Please enter valid email.`,
  },
  promocode: {
    required: `Please enter promocode.`,
  },
  discount_type: {
    required: `Please select Discount Type.`,
  },
  discount: {
    required: `Please enter discount.`,
    max: "Please enter upto 100% only.",
    maxlength: `Discounted price length should be 10 digits only`,
  },
  valid_from: {
    required: `Please select Valid From.`,
  },
  valid_to: {
    required: `Please select Valid To.`,
  },
  user_ids: {
    required: `Please select Users`,
  },
  name: {
    required: "Please enter name.",
    maxlength: `Name contain maximum 50 Char.`,
  },
  truckmaketitle: {
    required: "Please enter truck make name.",
  },
  truckmodeltitle: {
    required: "Please enter truck model name.",
  },
  vendor_name: {
    required: "Please enter vendor name.",
  },
  type: {
    required: "Please select depot type.",
  },
  street: {
    required: "Please enter street.",
  },
  local_government_id: {
    required: "Please select local government.",
  },
  no_of_trucks: {
    maxlength: "No. of truck contain maximum 8 digits.",
    pattern: "No. of truck should be numeric value",
  },
  driver_name: {
    required: "Please enter driver name.",
  },
  registration_no: {
    required: "Please enter registration no.",
    maxlength: "Registration-no contain maximum 15 char.",
  },
  driver_id: {
    required: "Please select driver",
  },
  storage_capacity: {
    required: "Please enter storage capacity",
    maxlength: "Storage capacity contain maximum 8 digits.",
  },
  depot_type: {
    required: "Please select Depot Type ",
    maxlength: "Storage capacity can be 8 digits long",
  },
  depot_id: {
    required: "Please select Depot",
  },
  retail_price: {
    required: "Please enter Retail Price",
  },
  bulk_price: {
    required: "Please enter Bulk Price",
  },
  date: {
    required: "Please enter Date",
  },
  bank_id: {
    required: "Please select Bank",
  },
  vessel_name: {
    required: "Please enter vessel name",
  },
  discharge_date: {
    required: "Please select discharge date",
  },
  discharge_qty: {
    required: "Please enter discharge qty.",
  },
  depot_received_qty: {
    required: "Please enter depot received qty.",
  },
  landed_cost_of_cargo: {
    required: "Please enter landed cost of cargo",
  },
  vender_id: {
    required: "Please select vender",
  },
  source_local_govt_id: {
    required: "Please select source local govt ",
  },
  destination_local_govt_id: {
    required: "Please select destination local govt",
  },
  transportation_rate: {
    required: "Please select Transportation rate",
  },
  customer_state_id: {
    required: "Please select state",
  },
  customer_source_local_govt_id: {
    required: "Please select source local govt",
  },
  customer_destination_local_govt_id: {
    required: "Please select destination local govt",
  },
  customer_transportation_rate: {
    required: "Please select Transportation rate",
  },
  location_name: {
    required: "Filling plant name is required",
  },
  first_name: {
    required: "First name is required",
  },
  last_name: {
    required: "Last name is required",
  },
  mob_rep_name: {
    required: "MOB Rep. Name is required",
  },
  mob_rep_phone_number: {
    required: `Phone number is required.`,
    minlength: `Phone number contain minimum 10 digits.`,
    maxlength: `Phone number contain maximum 10 digits.`,
    pattern: `Phone number should be numeric value.`,
  },
  role_id: {
    required: `Please select role.`,
  },
  quantity: {
    required: `Please enter quantity.`,
  },
};
