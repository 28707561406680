import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/mergeMap";
import "rxjs/add/operator/map";
import { Title } from "@angular/platform-browser";
import { CoreService } from "src/app/shared/services/core.service";

const SITE_ROUTES = {
  dashboardRoutes: {
    dashboard: "/dashboard",
  },
  PricingRoutes: {
    setPrice: "/set-prices", 
    pricingHistory: "/pricing-history",
    addStock: "/add-stock",
    currentStock: "/current-stock",
    stockHistory: "/stock-history",
  },
  OrdersRoutes: {
    availableStock: "/available-stock",
    addOrder: "/add-order",
    existingOrders: "/existing-orders",
    pendingOrders: "/pending-orders",
    deliveryOrders: "/delivery-order",
  },
};

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public isActivated = "";

  public Users;
  public permissions: any = {};

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private coreService: CoreService,
  ) {
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter((route) => route.outlet === "primary")
      .mergeMap((route) => route.data)
      .subscribe((event: any) => {
        this.isActivated = event.module;
        if (event.subModule) {
          this.isActivated = event.subModule;
        }
      });

    this.coreService.currentUser.subscribe((users) => {
      this.Users = users;
    });

    this.coreService.permission.subscribe((permissions: any) => {
      permissions.map((permis) => {
        let temp = this.permissions[permis.sub_module]
          ? this.permissions[permis.sub_module]
          : {};
        if (permis.action == "index" || permis.action == "add") {
          temp[permis.action] = permis.action;
          this.permissions[permis.sub_module] = temp;
        }
      });
    });
  }

  ngOnInit() {}

  showSidebar() {
    var element = document.getElementById("showSidebar");
    element.classList.toggle("mini-sidebar");
  }

  collapseSidebar(val) {
    if (val == this.isActivated) {
      this.isActivated = "";
    } else {
      this.isActivated = val;
    }
  }

  isRouteMatch(routesParent: string) {
    let match = false;
  }
}
