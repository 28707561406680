import { Component, OnInit } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { CoreService, staticAlert } from "src/app/shared/services/core.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { ActivatedRoute, Router } from "@angular/router";
import { Actors } from "../../shared/constants";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent {
  email = new FormControl("", [Validators.required, Validators.email]);
  password = new FormControl("", [
    Validators.required,
    Validators.required,
    Validators.minLength(6),
  ]);
  remember = new FormControl("");

  loginForm = new FormGroup({
    email: this.email,
    password: this.password,
  });

  isChecked = false;
  errorMessage: string;
  staticAlertClosed: boolean = false;
  constructor(
    private coreService: CoreService,
    private authservice: AuthService,
    private cookieService: CookieService,
    private readonly route: ActivatedRoute,
    private router: Router
  ) {
    if (this.cookieService.get("mob-remember")) {
      this.email.setValue(this.cookieService.get("mob-email"));
      this.password.setValue(this.cookieService.get("mob-password"));
      this.isChecked = true;
    }
  }

  ngOnInit() {}

  onSubmit() {
    if (this.loginForm.valid) {
      const data : any= Object.assign({}, this.loginForm.value);
      data.device_type = this.coreService.devicetype;
      // data.device_id = this.coreService.deviceId?this.coreService.deviceId:'11';
      data.device_id = 'ffnpxFdiz4J56KEH6GwGSZ:APA91bGHTcSacE8KFfbeyeGgsSBgYD2sQmh-lFylKGPxoLNVD5Z72YK2tJUL4ZkGvqThjGi0W_XBcizVrI4cultL5voUuwd6zlG9rTg1jLIx_hyXZiYEpiBbAPYCrfiitGVa-42rfo_d';
      // data.type = Actors.Admin;
      if (this.remember.value) {
        this.cookieService.set(
          "mob-email",
          data.email,
          3,
          "/",
          "",
          false,
          "Lax"
        );
        this.cookieService.set(
          "mob-password",
          data.password,
          3,
          "/",
          "",
          false,
          "Lax"
        );
        this.cookieService.set(
          "mob-remember",
          this.remember.value,
          3,
          "/",
          "",
          false,
          "Lax"
        );
      } else {
        this.cookieService.delete("mob-email", "/", "", false, "Lax");
        this.cookieService.delete("mob-password", "/", "", false, "Lax");
        this.cookieService.delete("mob-remember", "/", "", false, "Lax");
      }
      this.authservice.login(data).subscribe((resp) => {
        if (resp.success == 1) {
          this.cookieService.set(
            "accessToken",
            resp.data.auth.type + " " + resp.data.auth.token,
            1,
            "/",
            "",
            false,
            "Lax"
          );
          this.cookieService.set(
            "admin",
            JSON.stringify(resp.data.user),
            1,
            "/",
            "",
            false,
            "Lax"
          );
          this.coreService.currentUserSubject.next(resp.data.user.data);
          this.coreService.isAuthenticatedSubject.next(true);
          this.getUser();
          const returnUrl =
            this.route.snapshot.queryParams.returnUrl || "/admin/dashboard";
          this.coreService.navigateTo(returnUrl);
        }
      });
    }
  }

  getUser() {
    this.authservice.getProfile().subscribe((resp) => {
      if (resp.success == 1) {
        this.coreService.currentUserSubject.next(resp.data.user.data);
        this.cookieService.set("admin", JSON.stringify(resp.data.user.data), 1);
        this.coreService.permissionSubject.next(
          resp.data.user.data.permissions
        );
        this.coreService.pageSize = resp.data.settings
          ? resp.data.settings.value
          : "10";
      }
    });
  }
}
