import { Pipe, PipeTransform } from '@angular/core';
import { notiType } from "../constants";
@Pipe({
  name: 'notificationType'
})
export class NotificationTypePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case notiType.Email:
        return "Email Notification";
        break;
      case notiType.SMS:
        return "SMS Notification";
        break;
      case notiType.Push:
        return "Push Notification ";
        break;
    }
    return null;
  }

}
