import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ScaffoldComponent } from "./container/scaffold/scaffold.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ScaffoldComponent,
  ],
  imports: [
    CommonModule,
    NgbModule, 
    RouterModule,
    BsDropdownModule.forRoot()
  ]
})
export class ScaffoldModule {}
