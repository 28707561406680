import { Pipe, PipeTransform } from "@angular/core";
import { PendingOrderStatus } from "../constants";

@Pipe({
  name: "statusColor",
})
export class StatusColorPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case PendingOrderStatus.AwaitingPaymentConfirmation:
        return "label-danger";
        // return "label-info";
        break;
      case PendingOrderStatus.PaymentConfirmed:
        return "label-info";
        // return "label-info";
        break;
      case PendingOrderStatus.TruckNotimation:
        return "label-blue";
        // return "label-info";
        break;
      case PendingOrderStatus.Released:
        return "label-light-green";
        // return "label-info";
        break;
      case PendingOrderStatus.Cancelled:
        return "label-gray";
        // return "label-info";
        break;
      case PendingOrderStatus.Refunded:
        return "label-success";
        // return "label-info";
        break;
      case PendingOrderStatus.Completed:
        return "label-success";
        // return "label-info";
        break;
    }
    return null;
  }
}
