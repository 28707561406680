<!-- START LOGO WRAPPER -->
<nav class="top-toolbar navbar navbar-mobile navbar-tablet">
  <ul class="navbar-nav nav-left">
    <li class="nav-item">
      <a href="javascript:void(0)" data-toggle-state="aside-left-open">
        <i class="icon dripicons-align-left"></i>
      </a>
    </li>
  </ul>
  <ul class="navbar-nav nav-center site-logo">
    <li>
      <a routerLink="/admin/dashboard">
        <div class="logo_mobile">
          <img src="assets/img/logo.png" class="h-35" alt="Logo" />
          <sup class="text-white ml-1 text-uppercase">Admin</sup>
        </div>
      </a>
    </li>
  </ul>
  <ul class="navbar-nav nav-right">
    <li class="nav-item">
      <a  (click)="showHeader()" data-toggle-state="mobile-topbar-toggle">
        <i class="icon dripicons-dots-3 rotate-90"></i>
      </a>
    </li>
  </ul>
</nav>
<!-- END LOGO WRAPPER -->
<!-- START TOP TOOLBAR WRAPPER -->
<nav class="top-toolbar navbar navbar-desktop flex-nowrap">
  <!-- START RIGHT TOOLBAR ICON MENUS -->
  <ul class="navbar-nav nav-right">
    <!-- <li class="nav-item">
            <a (click)="showSearchBar()" class="open-search-button pointer">
                <i class="icon dripicons-search"></i>
            </a>
        </li> -->
    <li class="nav-item dropdown-notifications dropdown-menu-lg" ngbDropdown *ngIf="permissions.index || Users.actor == 1">
      <a ngbDropdownToggle class="pointer">
        <i class="icon dripicons-bell"></i>
        <span class="noti-count">{{ count }}</span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu-right p-0">
        <div class="card card-notification">
          <div class="card-header d-flex align-items-center">
            <h5 class="card-title m-0">Notifications</h5>
          </div>
          <div class="card-container0 mb-0">
            <div class="card-container-wrapper">
              <div
                class="card-body card-container mb-0"
                style="overflow-y: auto"
              >
                <div class="timeline timeline-border0">
                  <div
                    class="timeline-info0 mb-3"
                    *ngFor="let item of notificationList"
                    (click)="readNotification(item?.id)"
                  >
                    <div>{{ item?.message }}<br /></div>
                    <small class="text-muted">{{
                      item?.createdAt | date: "dd/MM/yyyy hh:mm a"
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix text-center py-3" style="clear: both">
              <a routerLink="/admin/notifications">View All</a>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="nav-item mr-4 ml-2" ngbDropdown>
      <a  class="nav-link nav-pill user-avatar pointer" ngbDropdownToggle>
        <img
          [src]="user?.image ? user?.image : 'assets/img/no-image.png'"
          class="w-35 h-35 rounded-circle"
          alt="User Name"
        />
        <span *ngIf="Users.actor==1" class="ml-2">{{ user?.business_name }}</span>
        <span *ngIf="Users.actor!=1" class="ml-2">{{ user?.name}} {{ user?.last_name  }}</span>
        <!-- <i class="la la-angle-down menu-arrow-down ml-2"></i> -->
      </a>
      <div  class="dropdown-menu-right dropdown-menu-accout dropdown-menu" ngbDropdownMenu>
        <div class="dropdown-header pb-3">
          <div class="media d-user">
            <img
              class="mr-3 w-40 h-40 rounded-circle"
              [src]="user?.image ? user?.image : 'assets/img/no-image.png'"
              alt="User Name"
            />
            <div class="media-body">
              <h5 *ngIf="Users.actor==1" class="text-wrap mt-0 mb-0">{{ user?.business_name }}</h5>
              <h5 *ngIf="Users.actor!=1" class="text-wrap mt-0 mb-0">{{ user?.name}} {{ user?.last_name  }}</h5>
              <span>{{ user?.email }}</span>
            </div>
          </div>
        </div>
        <a class="dropdown-item" *ngIf="Users.actor==1" routerLink="/admin/profile"
          ><i class="icon dripicons-user"></i> My Profile</a
        >
        <a class="dropdown-item" *ngIf="Users.actor!=1" routerLink="/admin/member-profile"
          ><i class="icon dripicons-user"></i> My Profile</a
        >
        <a class="dropdown-item" routerLink="/admin/profile/change-password"
          ><i class="icon dripicons-gear"></i> Change Password</a
        >
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" (click)="logout()"
          ><i class="icon dripicons-lock-open"></i> Log Out</a
        >
      </div>
    </li>
  </ul>
  <!-- END RIGHT TOOLBAR ICON MENUS -->
  <!--START TOP TOOLBAR SEARCH -->
  <form
    role="search"
    action=""
    class="navbar-form"
    [ngClass]="{ open: isSearchOpen }"
  >
    <div class="form-group">
      <input
        type="text"
        placeholder="Search and press enter..."
        class="form-control navbar-search"
        autocomplete="off"
      />
      <i
        (click)="showSearchBar()"
        class="icon dripicons-cross close-search"
      ></i>
    </div>
    <button type="submit" class="d-none">Submit</button>
  </form>
  <!--END TOP TOOLBAR SEARCH -->
</nav>
<!-- END TOP TOOLBAR WRAPPER -->
