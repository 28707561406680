import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
  selector: "[appCapitalizeFirst]",
})
export class CapitalizeFirstDirective {
  constructor(private el: ElementRef) {}

  @HostListener("keydown") onKeyDown() {
    if (this.el.nativeElement.value) {
      const arr: string[] = this.el.nativeElement.value.split("");
      arr[0] = arr[0].toUpperCase();
      this.el.nativeElement.value = arr.join("");
    }
  }
}
