<div class="main-container d-flex align-items-center justify-content-center">
  <div class="login-box">
    <div class="login-logo">
      <img src="assets/img/logo.png" class="" alt="Logo" />
    </div>
    <div class="login-box-body">
      <h1 class="text-center font-weight-600 mb-3">Password Recovery</h1>
      <p class="text-center mb-4">
        Please enter your Email ID below to reset your password
      </p>
      <form [formGroup]="fotgotPasswordForm" (submit)="onSubmit()">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Email ID"
            [formControl]="email"
          />
        </div>
        <button type="submit" class="btn btn-primary btn-block btn-lg">
          Submit
        </button>
      </form>
    </div>
    <div class="login-box-footer clearfix">
      <div class="form-group text-center mb-0">
        Remember Password? <a class="text-primary" routerLink="/login">Login</a>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-body text-center p-4">
    <h2 class="font-weight-600 mb-3">Password Recovery</h2>
    <p class="font-size-16 mb-2">
      An email has been sent to your registered email ID containg the password
      reset link along with a list of instructions.
    </p>
    <p class="font-size-16 mb-3">
      Please follow the steps mentioned in the email to reset your password.
    </p>
    <button
      class="btn btn-primary px-5"
      (click)="modal.close('Close click')"
      type="button"
    >
      Okay
    </button>
  </div>
</ng-template>
