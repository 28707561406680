<ngb-alert
  type="success"
  [dismissible]="false"
  *ngIf="isVerified"
  class="m-5"
  >{{ message }}</ngb-alert
>

<ngb-alert
  type="danger"
  [dismissible]="false"
  *ngIf="!isVerified"
  class="m-5"
  >{{ message }}</ngb-alert
>
