import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewInit,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "../../services/common.service";
import { CoreService, staticAlert } from "../../services/core.service";

@Component({
  selector: "app-bank-account",
  templateUrl: "./bank-account.component.html",
  styleUrls: ["./bank-account.component.scss"],
})
export class BankAccountComponent implements OnInit {
  @Input() bankDetails;
  @Input() isEdit;
  @Input() isShow;
  @Output() accountResolved = new EventEmitter<IBankInfo>();
  @Output() accountReject = new EventEmitter();
  selectBankCode: any = [];
  outputData = {} as IBankInfo;
  submitted = false;

  bank_code = new FormControl(null, [Validators.required]);
  nick_name = new FormControl(null);
  account_number = new FormControl(null, [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(10),
  ]);

  bankForm = new FormGroup({
    bank_code: this.bank_code,
    account_number: this.account_number,
    nick_name: this.nick_name,
  });
  // bankValidation = false;
  // accountValidation = false;

  constructor(
    private commonService: CommonService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.getBank();
  }

  AfterViewInit() {
    this.nick_name.setValidators([
      Validators.required,
      Validators.maxLength(100),
    ]);
  }

  get f() {
    return this.bankForm.controls;
  }

  onSelectBank(event) {
    this.outputData.bank_name = event.name;
    this.outputData.bank_code = event.code;
    this.onChanges();
    

  }

  getBank() {
    this.commonService.getBanks().subscribe((response) => {
      if (response.success == 1) {
        this.selectBankCode = JSON.parse(response.data.resp);
        this.selectBankCode = this.selectBankCode.data;
      }
      if (this.isEdit) {
        this.bank_code.patchValue(this.bankDetails.bank_code);
        this.account_number.patchValue(this.bankDetails.account_number);
        this.nick_name.patchValue(this.bankDetails.nick_name);
        this.outputData.bank_code = this.bankDetails.bank_code;
        this.outputData.bank_name = this.bankDetails.bank_name;
        this.outputData.account_name = this.bankDetails.account_name;
        // this.verifyAccount();
      }
    });
  }

  onChanges() {
    // this.accountReject.emit();
    this.accountReject.emit(this.outputData)
    
  }

  flushData() {
    this.outputData = {};
    this.bankForm.reset();
    this.submitted = false;
  }

  verifyAccount() {
    this.submitted = true;
    if (this.bankForm.valid) {
      let data = {
        bank_code: this.outputData.bank_code,
        account_number: this.account_number.value,
      };
      this.commonService.verifyAccount(data).subscribe((response) => {
        if (response.success == 1) {
          let res = JSON.parse(response.data.response);
          if (res.status) {
            this.outputData.account_number = res.data.account_number;
            this.outputData.account_name = res.data.account_name;
            this.outputData.nick_name = this.nick_name.value;
            this.accountResolved.emit(this.outputData);
          } else {
            const message: staticAlert = {
              message: res.message,
              type: "danger",
            };
            this.coreService.showMessge(message);
            this.accountReject.emit(this.outputData)
          }
        }
      });
    }
  }

  alphanumeric(event) {
    // let letters = /^[0-9a-zA-Z]+$/;
    let letters = /^[\w\s]+$/;
    if (event.key.match(letters)) {
      return event.key;
    } else {
      return false;
    }
  }
}

interface IBankInfo {
  bank_name?: string;
  account_number?: string;
  bank_code?: string;
  account_name?: string;
  nick_name?: string;
}
