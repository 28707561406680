import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryOrderStatus } from '../constants';

@Pipe({
  name: 'deliveryOrderStatus'
})
export class DeliveryOrderStatusPipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
        case DeliveryOrderStatus.AwaitingPaymentConfirmation:
            return "Awaiting Payment Confirmation";
            break;
        case DeliveryOrderStatus.PaymentConfirmed:
            return "Payment Confirmed";
            break;
        case DeliveryOrderStatus.OrderAssigned:
            return "Order Assigned";
            break;
        case DeliveryOrderStatus.OutForDelivery:
            return "Out for Delivery";
            break;
        case DeliveryOrderStatus.ArrivedDestination:
            return "Arrived At Destination";
            break;
        case DeliveryOrderStatus.Cancelled:
            return "Cancelled";
            break;
        case DeliveryOrderStatus.Refunded:
            return "Refunded";
            break;
        case DeliveryOrderStatus.CompleteDelivery:
            return "Completed";
            break;
    }
    return null;
}

}
