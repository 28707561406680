<div class="mb-3 mt-3">
  <h4 class="font-weight-600 m-0 mr-auto">Bank Details</h4>
</div>
<form [formGroup]="bankForm">
  <div class="form-row">
    <!-- 4,3,3,2 -->
    <div [ngClass]="{ 'col-xl-4': isShow, 'col-xl-5': !isShow }">
      <div class="form-group">
        <label class="control-label"
          >Select Bank
          <!-- <span class="text-danger">*</span> -->
        </label>
        <ng-select
          [items]="selectBankCode"
          bindLabel="name"
          bindValue="code"
          placeholder="-- Select Bank --"
          (change)="onSelectBank($event)"
          [formControl]="bank_code"
        ></ng-select>
        <div
          *ngIf="submitted && f.bank_code.errors"
          class="invalid-feedback custom-validation"
        >
          <p *ngIf="f.bank_code.errors.required" class="help text-danger">
            Please select bank.
          </p>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'col-xl-3': isShow, 'col-xl-4': !isShow }">
      <div class="form-group">
        <label>Account Number </label>
        <input
          type="text"
          class="form-control"
          [formControl]="account_number"
          (keyup)="onChanges()"
          [(ngModel)]="outputData.account_number"
          appNumberOnly
        />
        <div
          *ngIf="submitted && f.account_number.errors"
          class="invalid-feedback custom-validation"
        >
          <p *ngIf="f.account_number.errors.required" class="help text-danger">
            Please enter account number.
          </p>
          <p
            *ngIf="
              f.account_number.errors.minlength ||
              f.account_number.errors.maxlength
            "
            class="help text-danger"
          >
            Account number should be 10 digits long.
          </p>
        </div>
        <!-- (paste)="onlyNumberKey($event)" -->
      </div>
    </div>
    <div [ngClass]="{ 'col-xl-3': isShow, 'col-xl-3': !isShow }" *ngIf="isShow">
      <div class="form-group">
        <label class="control-label"
          >Nick Name
          <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          (keypress)="alphanumeric($event)"
          (keyup)="onChanges()"
          [formControl]="nick_name"
        />
        <div
          *ngIf="submitted && f.nick_name.errors"
          class="invalid-feedback custom-validation"
        >
          <p *ngIf="f.nick_name.errors.required" class="help text-danger">
            Please enter nick name.
          </p>
          <p *ngIf="f.nick_name.errors.maxlength" class="help text-danger">
            Nick name should be 100 char.
          </p>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'col-xl-2': isShow, 'col-xl-3': !isShow }">
      <div class="form-group">
        <label class="d-block">&nbsp; </label>
        <button class="btn btn-primary" (click)="verifyAccount()">
          <!-- [disabled]="!showVerify" -->
          Verify
        </button>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="form-group">
        <label>Account Name </label>
        <input
          type="text"
          class="form-control"
          placeholder=""
          value=""
          (keypress)="alphanumeric($event)"
          [(ngModel)]="outputData.account_name"
          [ngModelOptions]="{ standalone: true }"
          (keyup)="onChanges()"
          />
          <!-- disabled -->
      </div>
    </div>
  </div>
</form>
