import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appCapitalizeAll]",
})
export class CapitalizeAllDirective {
  constructor(private el: ElementRef) {}

  @HostListener("keyup") onKeyUp() {
    if (this.el.nativeElement.value) {
      // const arr: string[] = this.el.nativeElement.value.split("");
      // arr[0] = arr[0].toUpperCase();
      this.el.nativeElement.value = this.el.nativeElement.value.toUpperCase();
    }
  }
}
