import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { CoreService, staticAlert } from "../../services/core.service";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements OnInit {
  // @Input() type: any;
  // @Input() message: any;

  type: any = "info";
  message: string;
  staticAlertClosed = false;

  constructor(
    private coreService: CoreService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // this.coreService.showstaticAlert.subscribe((val: staticAlert) => {
    //   console.log(val);
    //   if (val != null) {
    //     this.type = val.type;
    //     this.message = val.message;
    //     this.staticAlertClosed = true;
    //     this.autoClose();
    //   } else {
    //     this.staticAlertClosed = false;
    //     // this.cdr.markForCheck();
    //   }
    // });
  }

  autoClose() {
    setTimeout(() => {
      this.staticAlertClosed = false;
      // this.coreService.showstaticAlertSubject.next(null);
      this.cdr.markForCheck();
    }, 5000);
  }

  closeAlert() {
    // this.coreService.showstaticAlertSubject.next(null);
    // this.cdr.markForCheck();
  }
}
