import { Component, OnInit } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { MustMatch } from "src/app/shared/validators/must-match";
import { CoreService, staticAlert } from "src/app/shared/services/core.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent implements OnInit {
  showMessage = false;

  password = new FormControl("", [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20),
  ]);
  confirm_password = new FormControl("", [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20),
    MustMatch("password"),
  ]);

  passwordForm = new FormGroup({
    password: this.password,
    confirm_password: this.confirm_password,
  });
  token: any;
  message: any;

  constructor(
    private coreService: CoreService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((param) => {
      if (param && param.token) {
        this.token = parseInt(param.token);
      }
    });
  }
  onSubmit() {
    if (this.passwordForm.valid) {
      if (this.password.value === this.confirm_password.value) {
        this.authService
          .resetPassword({
            token: this.token,
            password: this.confirm_password.value,
          })
          .subscribe((resp) => {
            if (resp.success == 1) {
              // this.coreService.showSuccess(resp.data.message);
              const message: staticAlert = {
                message: resp.data.message,
                type: "success",
              };
              this.coreService.showMessge(message);
              this.showMessage = true;
              this.message = resp.data.message;
              if (resp.data.actor == 1 || resp.data.actor == 2) {
                this.coreService.navigateTo("/login");
              }
            }
          });
      } else {
        const message: staticAlert = {
          message: "New Password does not match with confirm Password.",
          type: "success",
        };
        this.coreService.showMessge(message);
      }
    }
  }
}
