import { Pipe, PipeTransform } from "@angular/core";
import { PendingOrderStatus } from "../constants";

@Pipe({
  name: "nextStatus",
})
export class NextStatusPipe implements PipeTransform {
  transform(value: any, is_confirm: number): any {
    if (is_confirm == 0) {
      return "Confirm Payment";
    } else {
      switch (value) {
        case PendingOrderStatus.AwaitingPaymentConfirmation:
          return "Confirm Payment";
          break;
        case PendingOrderStatus.PaymentConfirmed:
          return "Add Nominations";
          break;
        case PendingOrderStatus.TruckNotimation:
          return "Release Order";
          break;
        case PendingOrderStatus.Released:
          return "Complete";
          break;
      }
    }
    return null;
  }
}

// import { Pipe, PipeTransform } from "@angular/core";
// import { PendingOrderStatus } from "../constants";

// @Pipe({
//   name: "nextStatus",
// })
// export class NextStatusPipe implements PipeTransform {
//   transform(value: any, is_confirm: number): any {
//     if (is_confirm == 0) {
//       return "Confirm Payment";
//     } else {
//       switch (value) {
//         case PendingOrderStatus.AwaitingPaymentConfirmation:
//           return "Confirm Payment";
//         case PendingOrderStatus.PaymentConfirmed:
//           return "Add Nominations";
//         case PendingOrderStatus.TruckNotimation:
//           return "Release Order";
//         case PendingOrderStatus.Released:
//           return "Complete";
//         default:
//           return null;
//       }
//     }
//   }
// }
