import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { CoreService, staticAlert } from "../services/core.service";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private coreService: CoreService,
    private router: Router,
    private CookieService: CookieService,
    private toastrService: ToastrService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(
      tap((evt) => {
        // console.log(request,'request');
        // console.log(evt,'evt');
        
        
        ;
        if (evt instanceof HttpResponse) {
          ;
          if (evt.body.success == 0) {
            ;
            if (evt.body.error.message) {
              this.toastrService.error(evt.body.error.message, "Oops!");
              // const errorSubject: staticAlert = {
              //   type: "danger",
              //   message: evt.body.error.message,
              // };
              // this.coreService.showstaticAlertSubject.next(errorSubject);
            } else if (evt.body.error.length > 0) {
              // const errorSubject: staticAlert = {
              //   type: "danger",
              //   message: evt.body.error[0],
              // };
              // this.coreService.showstaticAlertSubject.next(errorSubject);
              this.toastrService.error(evt.body.error[0], "Oops!");
            } else {
              // const errorSubject: staticAlert = {
              //   type: "danger",
              //   message: "Something went wrong.",
              // };
              // this.coreService.showstaticAlertSubject.next(errorSubject);
              this.toastrService.error("Something went wrong", "Oops!");
            }
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        ;
        // console.log(error);
        // ;
        let errorResp = {} as staticAlert;
        if (error.status === 401) {
          // errorResp.message =
          //   "You are unauthorized to access this resource, Please login again";
          this.toastrService.error(
            "You are unauthorized to access this resource, Please login again",
            "Oops!"
          );
          this.coreService.purgeAuth();
          this.CookieService.delete("accessToken");
          this.CookieService.delete("admin");
          localStorage.clear();
          this.router.navigateByUrl("/");
          location.reload();
        }
        if (error.error instanceof ErrorEvent) {
          // ;
          // errorResp = {
          //   type: "danger",
          //   message: error.error.error.message,
          // };

          // this.coreService.showstaticAlertSubject.next(errorResp);
          this.toastrService.error(error.error.error.message, "Oops!");
        }
        return throwError(error);
      })
    );
  }
}
