import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appFormatPhoneNumber]",
})
export class FormatPhoneNumberDirective {
  constructor(private el: ElementRef) {}

  @HostListener("keyup") onKeyUp() {
    if (this.el.nativeElement.value) {
      const arr: string[] = this.el.nativeElement.value.split("");
      if (Number(arr[0]) == 0) {
        this.el.nativeElement.value = "";
      } else {
        // if (arr.length == 3) {
        //   this.el.nativeElement.value = arr.join("").concat("-");
        // } else if (arr.length == 7) {
        //   this.el.nativeElement.value = arr.join("").concat("-");
        // } else {
        this.el.nativeElement.value = arr.join("");
        // }
      }
    }
  }

  // @HostListener("focus") onFocus() {
  //   if (this.el.nativeElement.value) {
  //     const arr: string[] = this.el.nativeElement.value.split("");
  //     // console.log(arr.length);
  //     if (arr.length > 0) {
  //       if (arr[3] != "-") arr.splice(3, 0, "-");
  //       if (arr[7] != "-") arr.splice(7, 0, "-");
  //       console.log(arr);
  //       this.el.nativeElement.value = arr.join("");
  //     }
  //     // else {
  //     //   if (arr.length == 3) {
  //     //     this.el.nativeElement.value = arr.join("").concat("-");
  //     //   } else if (arr.length == 7) {
  //     //     this.el.nativeElement.value = arr.join("").concat("-");
  //     //   } else {
  //     //     this.el.nativeElement.value = arr.join("");
  //     //   }
  //     // }
  //   }
  // }
}
