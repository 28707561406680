import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullWithDefault',
})
export class NullWithDefaultPipe implements PipeTransform {
  transform(value: any): any {
    if (
      typeof value === 'undefined' ||
      value === null ||
      value === 'null' ||
      value === undefined ||
      value === ''
    ) {
      return '-';
    }
    return value;
  }
}
