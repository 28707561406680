import { Pipe, PipeTransform } from "@angular/core";
import { PaymentType } from "../constants";

@Pipe({
  name: "paymentMethod",
})
export class PaymentMethodPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    switch (value) {
      case PaymentType.OnlinePayment:
        return "Online";
        break;
      case PaymentType.BankTransfer:
        return "Bank Transfer";
        break;
      case PaymentType.Credit:
        return "Credit ";
        break;
    }
  }
}
