import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessageComponent } from "./components/message/message.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoaderComponent } from "./components/loader/loader.component";
import { FormatPhoneNumberDirective } from "./directives/format-phone-number.directive";
import { OrderStatusPipe } from "./pipes/order-status.pipe";
import { StatusColorPipe } from "./pipes/status-color.pipe";
import { NextStatusPipe } from "./pipes/next-status.pipe";
import { PaymentMethodPipe } from "./pipes/payment-method.pipe";
import { DeliveryOrderStatusPipe } from "./pipes/delivery-order-status.pipe";
import { DeliveryOrderStatusColorsPipe } from "./pipes/delivery-order-status-colors.pipe";
import { TripStatusPipe } from "./pipes/trip-status.pipe";
import { BankAccountComponent } from "./components/bank-account/bank-account.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NumberOnlyDirective } from "./directives/number-only.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormValidationsModule } from "./form-validations/form-validations.module";
import { NotificationTypePipe } from './pipes/notification-type.pipe';
import { OrderTypePipe } from './pipes/order-type.pipe';
import { NgxMaskModule } from "ngx-mask";
import { NullWithDefaultPipe } from "./pipes/null-with-default.pipe";
@NgModule({
  declarations: [
    MessageComponent,
    LoaderComponent,
    FormatPhoneNumberDirective,
    OrderStatusPipe,
    StatusColorPipe,
    NextStatusPipe,
    PaymentMethodPipe,
    NullWithDefaultPipe,
    DeliveryOrderStatusPipe,
    DeliveryOrderStatusColorsPipe,
    TripStatusPipe,
    BankAccountComponent,
    NumberOnlyDirective,
    NotificationTypePipe,
    OrderTypePipe,
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forChild()
  ],
  exports: [
    MessageComponent,
    LoaderComponent,
    FormatPhoneNumberDirective,
    OrderStatusPipe,
    StatusColorPipe,
    NextStatusPipe,
    NullWithDefaultPipe,
    PaymentMethodPipe,
    DeliveryOrderStatusPipe,
    DeliveryOrderStatusColorsPipe,
    BankAccountComponent,
    NumberOnlyDirective,
    NotificationTypePipe,
    OrderTypePipe,
  ],
})
export class SharedModule {}
