import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ScaffoldComponent } from "./admin/scaffold/container/scaffold/scaffold.component";
const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  {
    path: "cms-page",
    loadChildren: () =>
      import("./cms-pages/cms-pages.module").then((m) => m.CmsPagesModule),
  },

  {
    path: "admin",
    component: ScaffoldComponent,
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
