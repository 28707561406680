import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private apiService: ApiService) {}

  getAdminDashboard(data): Observable<any> {
    return this.apiService.get("/dashboard/admin",data);
  }

  getState(): Observable<any> {
    return this.apiService.get("/common/states");
  }

  getLocalGovt(data): Observable<any> {
    return this.apiService.post("/common/localGovts", data);
  }

  getMake(): Observable<any> {
    return this.apiService.get("/common/make");
  }

  getModel(data): Observable<any> {
    return this.apiService.post("/common/models", data);
  }

  getdepots(id): Observable<any> {
    return this.apiService.get(`/common/depotlist/${id}`);
  }

  getAlldepots(): Observable<any> {
    return this.apiService.get("/common/getalldepotlist");
  }

  alldepotPrice(): Observable<any> {
    return this.apiService.get("/common/alldepotPrice");
  }

  getDepotpPrice(data):Observable<any>{
    return this.apiService.post("/order/getPrizeByDate",data)
  }

  getbackaccounts(): Observable<any> {
    return this.apiService.get("/common/getbanklist");
  }

  getvenderlist(): Observable<any> {
    return this.apiService.get("/common/venderlist");
  }

  gettrucklist(id, user_type?): Observable<any> {
    return this.apiService.post(`/common/gettrucklist/${id}`, {
      user_type: user_type ? user_type : null,
    });
  }

  checkVendorData(data): Observable<any> {
    return this.apiService.post(`/common/checkVendorData`, data);
  }

  checkDriverData(data): Observable<any> {
    return this.apiService.post(`/common/checkDriverData`, data);
  }

  uploadImage(data): Observable<any> {
    return this.apiService.formDataPost("/common/sendFileToS3", data);
  }

  getPromocodes(id): Observable<any> {
    return this.apiService.get(`/common/promocodes/${id}`);
  }

  getCustomerPlansHandler(data): Observable<any> {
    return this.apiService.post(`/common/getFillingPlants`, data);
  }

  getAdditionalFees(data): Observable<any> {
    return this.apiService.post(`/common/getAdditionalFees`, data);
  }

  getBanks(): Observable<any> {
    return this.apiService.get("/common/getBanks");
  }

  masterData(): Observable<any> {
    return this.apiService.get("/common/masterData");
  }

  verifyAccount(data): Observable<any> {
    return this.apiService.post(`/common/verifyAccount`, data);
  }

  editPromocode(data):Observable<any>{
    return this.apiService.post(`/order/editOrder`,data)
  }



}
