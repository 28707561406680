export enum cms_type {
  Customers = 1,
  Drivers = 2,
}

export enum Actors {
  Admin = 1,
  Subadmin = 2,
  Vendor = 3,
}

export enum depotType {
  Inland = 1,
  Coastal = 2,
}

export enum tripPointValue {
  onePoint = 100,
}

export enum PendingOrderStatus {
  AwaitingPaymentConfirmation = 1,
  PaymentConfirmed = 2,
  TruckNotimation = 3,
  Released = 4,
  Completed = 5,
  Cancelled = 6,
  Refunded = 7,
}

export enum PaymentType {
  OnlinePayment = 1,
  BankTransfer = 2,
  Credit = 3,
}

export enum promocodeUsageStatus {
  Pending = 0,
  Expired = 1,
  Redeemed = 2,
}


export enum OrderType {
  PickUp = 1,
  Delivery = 2,
}

export enum DeliveryOrderStatus {
  AwaitingPaymentConfirmation = 1,
  PaymentConfirmed = 2,
  OrderAssigned = 3,
  OutForDelivery = 4,
  ArrivedDestination = 5,
  CompleteDelivery = 6,
  Cancelled = 7,
  Refunded = 8,
}
export enum paystackStatus {
  Pending = 0,
  Requested = 1,
  Completed = 2,
  Failed =3,
  Reversed=4,//as per paystack webhooo : https://paystack.com/docs/payments/webhooks/
}

export enum notiType {
  Push=1,
  Email=2,
  SMS=3,
}