import { Pipe, PipeTransform } from '@angular/core';
import { OrderType } from '../constants';
@Pipe({
  name: 'orderType'
})
export class OrderTypePipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case OrderType.Delivery:
        return "Delivery";
        break;
      case OrderType.PickUp:
        return "Pickup";
        break;
    }
    return null;
  }

}
