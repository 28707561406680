import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Subject } from "rxjs";
import { CoreService } from "./shared/services/core.service";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "./shared/services/auth.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/mergeMap";
import "rxjs/add/operator/map";
import { PushNotificationService } from "./shared/services/push-notification.service";
import { NotificationService } from "./shared/services/notification.service";
import { fadeAnimation } from "./route-animation";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  // animations: [fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = "adminpanel";
  // isLoading = this.coreService.isLoading$;
  isLoading = false;
  logindata: any;
  constructor(
    private coreService: CoreService,
    private cookieService: CookieService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private messagingService: PushNotificationService,
    private notificationService: NotificationService,
    private toastrService: ToastrService
  ) {
    this.startNotificationService();
  }
  ngOnInit(): void {
    this.coreService.isLoading$.subscribe((x) => {
      this.isLoading = x;
      this.cd.markForCheck();
    });

    const isData = this.cookieService.check("admin");
    if (isData) {
      this.logindata = JSON.parse(this.cookieService.get("admin"));
      this.coreService.currentUserSubject.next(this.logindata);
      this.coreService.isAuthenticatedSubject.next(true);
      this.getUser();
      this.getNotificationList();
    } else {
      this.coreService.currentUserSubject.next(null);
      this.coreService.isAuthenticatedSubject.next(false);
    }
    this.checkPermission();

    this.messagingService.currentMessage.subscribe((payload) => {
      if(payload) {
        if (payload.data) {
            this.toastrService.info(payload.data.message, payload.data.title);
         }
      }
        this.cd.markForCheck();
    });
  }

  checkPermission() {
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter((route) => route.outlet === "primary")
      .mergeMap((route) => route.data)
      .subscribe((event: any) => {
        this.titleService.setTitle("MobGas - Admin");
        if (event.title) {
          this.titleService.setTitle(event.title + " | MobGas - Admin");
        }
        this.coreService.permission.subscribe((role: any) => {
          if (this.logindata && this.logindata.actor == 2) {
            if (role && event["module"] && event["action"]) {
              const check = role.filter(
                (elem) =>
                  elem.controller == event["module"] &&
                  elem.action == event["action"]
              );

              if (check.length == 0) {
                this.router.navigateByUrl("/admin/dashboard");
                this.coreService.showMessge({
                  type: "warning",
                  message: "You are not authorized to access this resource",
                });
                return;
              }
            }
          }
          // else {
          //   this.router.navigateByUrl("/dashboard");
          // }
        });
      });
  }

  getUser() {
    this.authService.getProfile().subscribe((resp) => {
      if (resp.success == 1) {
        this.coreService.currentUserSubject.next(resp.data.user.data);
        this.cookieService.set("admin", JSON.stringify(resp.data.user.data), 1);

        this.coreService.permissionSubject.next(
          resp.data.user.data.permissions
        );
        this.coreService.pageSize = resp.data.settings
          ? resp.data.settings.value
          : "10";
      }
    });
  }

  getNotificationList() {
    // console.log('app.comp');
    this.notificationService.getBellNotifications().subscribe((response) => {
      if (response.success == 1) {
        this.coreService.notificationListSubject.next(response.data);
        this.cd.markForCheck();
      }
    });
  }

  startNotificationService() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
  }
}
