<div
  class="main-container d-flex align-items-center justify-content-center"
  *ngIf="!showMessage"
>
  <div class="login-box">
    <div class="login-logo">
      <img src="assets/img/logo.png" class="" alt="Logo" />
    </div>
    <div class="login-box-body">
      <h1 class="login-box-msg font-weight-600">Reset Password</h1>
      <form (submit)="onSubmit()" [formGroup]="passwordForm">
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Set New Password"
            [formControl]="password"
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Retype New Password"
            [formControl]="confirm_password"
          />
        </div>
        <button
          type="submit"
          class="btn btn-primary btn-block btn-lg"
          [disabled]="!passwordForm.valid"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</div>

<ngb-alert
  type="success"
  [dismissible]="false"
  *ngIf="showMessage"
  class="m-5"
  >{{ message }}</ngb-alert
>
