import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ControlErrorsDirective } from "./directive/control-errors.directive";
import { ControlErrorComponent } from "./control-error/control-error.component";
import { ControlErrorContainerDirective } from "./directive/control-error-container.directive";
import { FormSubmitDirective } from "./directive/form-submit.directive";
import { CapitalizeFirstDirective } from "./directive/capitalize-first.directive";
import { CapitalizeAllDirective } from "./directive/capitalize-all.directive";

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, FormsModule],
    declarations: [
        ControlErrorsDirective,
        ControlErrorComponent,
        ControlErrorContainerDirective,
        FormSubmitDirective,
        CapitalizeFirstDirective,
        CapitalizeAllDirective,
    ],
    exports: [
        ControlErrorsDirective,
        ControlErrorComponent,
        ControlErrorContainerDirective,
        FormSubmitDirective,
        CapitalizeFirstDirective,
        CapitalizeAllDirective,
    ]
})
export class FormValidationsModule {}
