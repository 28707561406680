import { Injectable } from "@angular/core";
import { ReplaySubject, Subject, BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { TokenService } from "./token.service";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { ToastrService } from "ngx-toastr";
import { takeUntil } from "rxjs/operators";
import { map } from "rxjs-compat/operator/map";

@Injectable({
  providedIn: "root",
})
export class CoreService {
  deviceId = "";
  devicetype = "A";

  public isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  public currentUserSubject = new ReplaySubject(1);
  public currentUser = this.currentUserSubject.asObservable();

  public notificationListSubject = new ReplaySubject<any>(1);
  public notificationLists = this.notificationListSubject.asObservable();

  //for permission array
  public permissionSubject = new ReplaySubject(1);
  public permission = this.permissionSubject.asObservable();

  // Current Module Permissions
  public currentPermissionSubject = new ReplaySubject(1);
  public currentPermission = this.currentPermissionSubject.asObservable();

  // public showstaticAlertSubject = new ReplaySubject<staticAlert>(null);
  // public showstaticAlert = this.showstaticAlertSubject.asObservable();

  // public staticAlertTypeSubject = new ReplaySubject(null);
  // public staticAlertType = this.showstaticAlertSubject.asObservable();

  // public staticAlertMessageSubject = new ReplaySubject(null);
  // public staticAlertMessage = this.showstaticAlertSubject.asObservable();

  // isLoading = new Subject<boolean>();

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isLoading$ = this.isLoading.asObservable();

  public pageSize = 10;

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private toastrService: ToastrService
  ) {
    // this.isLoading.next(false);
  }

  showLoading() {
    this.isLoading.next(true);
  }
  hideLoading() {
    this.isLoading.next(false);
  }

  navigateTo(path: string, params = null) {
    if (params) {
      this.router.navigateByUrl(path);
    }
    this.router.navigate([path]);
  }

  purgeAuth() {
    this.tokenService.destroyToken();
    this.isAuthenticatedSubject.next(false);
    this.currentUserSubject.next();
    this.permissionSubject.next([]);
  }

  showMessge(resp: staticAlert) {
    switch (resp.type) {
      case "success":
        this.toastrService.success(resp.message, "Success!");
        break;
      case "warning":
        this.toastrService.warning(resp.message, "Alert!");
        break;
      case "danger":
        this.toastrService.error(resp.message, "Oops!");
        break;
      default:
        this.toastrService.info(resp.message, "Info");
        break;
    }
  }

  // showSuccess(message) {
  //   Swal.fire({
  //     title: "Success",
  //     text: message,
  //     icon: "success",
  //   });
  // }

  // showError(message) {
  //   Swal.fire({
  //     text: message,
  //   });
  // }

  // showWarning(message) {
  //   Swal.fire({
  //     text: message,
  //     icon: "warning",
  //   });
  // }

  getYearsDropdown() {
    const SelectTruckYear = [];
    for (let i = 0; i <= 30; i++) {
      SelectTruckYear.push(new Date().getFullYear() - i);
    }
    return SelectTruckYear;
  }

  getAccess(controllerName: string, actionName: string) {
    // const gotResponse$ = new Subject();
    // this.permission
    //   .pipe(
    //     takeUntil(gotResponse$),
    //     map((perms: any) => {
    //       perms
    //         .filter((elem) => elem.controller == controllerName)
    //         .map((x) => {
    //           // this.permission[x.action] = true;
    //         });
    //       gotResponse$.next();
    //     })
    //   )
    //   .subscribe();
  }
  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }
}

export interface staticAlert {
  type: "success" | "danger" | "info" | "warning";
  message: string;
}

export interface currentPermission {
  index: boolean;
  view: boolean;
  add: boolean;
  edit: boolean;
  delete: boolean;
  status: boolean;
  export: boolean;
}
