<div class="aside-toolbar">
  <ul class="header-controls">
    <li class="nav-item menu-trigger">
      <button
        type="button"
        class="btn btn-link btn-menu"
        (click)="showSidebar()"
      >
        <i class="fa fa-bars"></i>
      </button>
    </li>
  </ul>
  <ul class="site-logo">
    <li>
      <!-- START LOGO -->
      <a routerLink="/admin/dashboard">
        <div class="logo">
          <img src="assets/img/logo.png" class="h-35" alt="Logo" />
          <sup class="text-white ml-1 text-uppercase">Admin</sup>
        </div>
        <!-- <span class="brand-text">QuantumPro</span> -->
      </a>
      <!-- END LOGO -->
    </li>
  </ul>

  <!-- <ul class="header-controls">
            <li class="nav-item menu-trigger">
                <button type="button" class="btn btn-link btn-menu" data-toggle-state="mini-sidebar" data-key="leftSideBar">
                    <i class="la la-dot-circle-o"></i>
                </button>
            </li>
        </ul> -->
</div>
<aside class="sidebar sidebar-left">
  <div class="sidebar-content">
    <nav class="main-menu">
      <ul class="nav metismenu">
        <!-- <li class="sidebar-header"><span>NAVIGATION</span></li> -->

        <li routerLinkActive="active">
          <a routerLink="/admin/dashboard">
            <i class="icon dripicons-meter"></i>
            <span>Dashboard</span>
          </a>
        </li>

        <li
          [className]="
            isActivated == 'pricing' ? 'nav-dropdown active' : 'nav-dropdown'
          "
          *ngIf="
            permissions['Set Price']?.index ||
            permissions['Pricing History']?.index ||
            permissions['Add Stock']?.add ||
            permissions['Current Stock']?.index ||
            permissions['Stock History']?.index ||
            Users?.actor == 1
          "
        >
          <a
            class="has-arrow"
            (click)="collapseSidebar('pricing')"
            [attr.aria-expanded]="isActivated == 'pricing' ? true : false"
            aria-controls="collapseExample"
          >
            <i class="icon dripicons-ticket"></i>
            <span>Pricing & Stock</span>
          </a>
          <ul
            class="nav-sub"
            [ngbCollapse]="isActivated == 'pricing' ? false : true"
            [attr.aria-expanded]="isActivated == 'pricing' ? true : false"
          >
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('pricing')"
              *ngIf="permissions['Set Price']?.index || Users?.actor == 1"
            >
              <a class="" routerLink="/admin/pricing-stock/set-prices">
                <span>Set Prices </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('pricing')"
              *ngIf="permissions['Pricing History']?.index || Users?.actor == 1"
            >
              <a routerLink="/admin/pricing-stock/pricing-history">
                <span>Pricing History </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('pricing')"
              *ngIf="permissions['Add Stock']?.add || Users?.actor == 1"
            >
              <a routerLink="/admin/pricing-stock/add-stock">
                <span>Add Stock </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('pricing')"
              *ngIf="permissions['Current Stock']?.index || Users?.actor == 1"
            >
              <a routerLink="/admin/pricing-stock/current-stock">
                <span>Current Stock </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('pricing')"
              *ngIf="permissions['Stock History']?.index || Users?.actor == 1"
            >
              <a routerLink="/admin/pricing-stock/stock-history">
                <span>Stock History </span>
              </a>
            </li>
          </ul>
        </li>

        <li
          class="nav-dropdown"
          [class.active]="isActivated == 'orders'"
          *ngIf="
            permissions['Available Stock']?.index ||
            permissions['Add New Order']?.add ||
            permissions['Manage Existing Orders']?.index ||
            Users?.actor == 1
          "
        >
          <a
            class="has-arrow"
            (click)="collapseSidebar('orders')"
            [attr.aria-expanded]="isActivated == 'orders' ? true : false"
            aria-controls="collapseExample"
          >
            <i class="icon dripicons-to-do"></i>
            <span>Manage Orders</span>
          </a>
          <ul
            class="nav-sub"
            [ngbCollapse]="isActivated == 'orders' ? false : true"
            [attr.aria-expanded]="isActivated == 'orders' ? true : false"
          >
            <li
              routerLinkActive="active"
              *ngIf="permissions['Available Stock']?.index || Users?.actor == 1"
              (click)="collapseSidebar('orders')"
            >
              <a routerLink="/admin/orders/available-stock">
                <span>Available Stock </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              *ngIf="permissions['Add New Order']?.add || Users?.actor == 1"
              (click)="collapseSidebar('orders')"
            >
              <a routerLink="/admin/orders/add-order">
                <span>Add New Order </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              *ngIf="
                permissions['Manage Existing Orders']?.index ||
                Users?.actor == 1
              "
              (click)="collapseSidebar('orders')"
            >
              <a routerLink="/admin/orders/existing-orders">
                <span>Manage Existing Orders </span>
              </a>
            </li>
          </ul>
        </li>

        <li
          class="nav-dropdown"
          [className]="
            isActivated == 'customer' ? 'nav-dropdown active' : 'nav-dropdown'
          "
          *ngIf="
            permissions['Add Customer']?.add ||
            permissions['Existing Customers']?.index ||
            Users?.actor == 1
          "
        >
          <a
            class="has-arrow"
            (click)="collapseSidebar('customer')"
            [attr.aria-expanded]="isActivated == 'customer' ? true : false"
            aria-controls="collapseExample"
          >
            <i class="icon dripicons-user-group"></i>
            <span>Manage Customers</span>
          </a>
          <ul
            class="nav-sub"
            [ngbCollapse]="isActivated == 'customer' ? false : true"
            [attr.aria-expanded]="isActivated == 'customer' ? true : false"
          >
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('customer')"
              *ngIf="permissions['Add Customer']?.add || Users?.actor == 1"
            >
              <a routerLink="/admin/customers/add-customer">
                <span>Add New Customer </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('customer')"
              *ngIf="
                permissions['Existing Customers']?.index || Users?.actor == 1
              "
            >
              <a routerLink="/admin/customers/existing-customer">
                <span>Existing Customers </span>
              </a>
            </li>
          </ul>
        </li>

        <li
          class="nav-dropdown"
          [class.active]="isActivated == 'vendors'"
          *ngIf="
            permissions['Add Transport Vendors']?.add ||
            permissions['Existing Transport Vendors']?.index ||
            permissions['Vendor Drivers']?.index ||
            permissions['Vendor Payment']?.index ||
            Users?.actor == 1
          "
        >
          <a
            class="has-arrow"
            (click)="collapseSidebar('vendors')"
            [attr.aria-expanded]="isActivated == 'vendors' ? true : false"
            aria-controls="collapseExample"
          >
            <i class="icon dripicons-user-group"></i>
            <span
              >Manage Transport <br />
              <span class="pl-34">Vendors</span>
            </span>
          </a>
          <ul
            class="nav-sub"
            [ngbCollapse]="isActivated == 'vendors' ? false : true"
            [attr.aria-expanded]="isActivated == 'vendors' ? true : false"
          >
            <li
              routerLinkActive="active"
              *ngIf="
                permissions['Add Transport Vendors']?.add || Users?.actor == 1
              "
              (click)="collapseSidebar('vendors')"
            >
              <a routerLink="/admin/transport-vendors/add-transport-vendor">
                <span>Add New Transport Vendor </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              *ngIf="
                permissions['Existing Transport Vendors']?.index ||
                Users?.actor == 1
              "
              (click)="collapseSidebar('vendors')"
            >
              <a
                routerLink="/admin/transport-vendors/existing-transport-vendors"
              >
                <span>Existing Transport Vendors </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              *ngIf="permissions['Vendor Drivers']?.index || Users?.actor == 1"
              (click)="collapseSidebar('vendors')"
            >
              <a routerLink="/admin/transport-vendors/drivers">
                <span>Drivers </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              *ngIf="permissions['Vendor Payment']?.index || Users?.actor == 1"
              (click)="collapseSidebar('vendors')"
            >
              <a routerLink="/admin/transport-vendors/vendor-payments">
                <span>Vendor Payments </span>
              </a>
            </li>
          </ul>
        </li>

        <li routerLinkActive="active" (click)="collapseSidebar('')">
          <a
            routerLink="/admin/delivery-operations/load-trucks"
            *ngIf="
              permissions[' Delivery Orders Received']?.index ||
              permissions['Loading Operations']?.index ||
              permissions['Loading & Delivery Activity']?.index ||
              permissions['Assign Loaded Trucks']?.index ||
              permissions['On Trip']?.index ||
              permissions['Trip History']?.index ||
              Users?.actor == 1
            "
          >
            <i class="icon dripicons-user-group"></i>
            <span
              >Manage Delivery <br />
              <span class="pl-34">Operations</span>
            </span>
          </a>
        </li>

        <li
          class="nav-dropdown"
          [className]="
            isActivated == 'mobfleet' ? 'nav-dropdown active' : 'nav-dropdown'
          "
          *ngIf="
            permissions['MOB Truck Fleet']?.index ||
            permissions['MOB Driver']?.index ||
            Users?.actor == 1
          "
        >
          <a
            class="has-arrow"
            (click)="collapseSidebar('mobfleet')"
            [attr.aria-expanded]="isActivated == 'mobfleet' ? true : false"
            aria-controls="collapseExample"
          >
            <i class="icon dripicons-help"></i>
            <span>Manage MOB Fleet</span>
          </a>
          <ul
            class="nav-sub"
            [ngbCollapse]="isActivated == 'mobfleet' ? false : true"
            [attr.aria-expanded]="isActivated == 'mobfleet' ? true : false"
          >
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('mobfleet')"
              *ngIf="permissions['MOB Truck Fleet']?.index || Users?.actor == 1"
            >
              <a routerLink="/admin/mob-fleet/truck-fleet">
                <span>Truck Fleet </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('mobfleet')"
              *ngIf="permissions['MOB Driver']?.index || Users?.actor == 1"
            >
              <a routerLink="/admin/mob-fleet/drivers">
                <span>MOB Drivers</span>
              </a>
            </li>

            <li
              routerLinkActive="active"
              (click)="collapseSidebar('mobfleet')"
              *ngIf="permissions['MOB Driver Payment']?.index || Users?.actor == 1"
            >
              <a routerLink="/admin/mob-fleet/driver-payment">
                <span>Driver Payment</span>
              </a>
            </li>
          </ul>
        </li>

        <li
          class="nav-dropdown"
          [className]="
            isActivated == 'depots' ? 'nav-dropdown active' : 'nav-dropdown'
          "
          *ngIf="
            permissions['Manage Depots']?.index ||
            permissions['Add New Depot']?.add ||
            Users?.actor == 1
          "
        >
          <a
            class="has-arrow"
            (click)="collapseSidebar('depots')"
            [attr.aria-expanded]="isActivated == 'depots' ? true : false"
            aria-controls="collapseExample"
          >
            <i class="icon dripicons-store"></i>
            <span>Manage Depots</span>
          </a>
          <ul
            class="nav-sub"
            [ngbCollapse]="isActivated == 'depots' ? false : true"
            [attr.aria-expanded]="isActivated == 'depots' ? true : false"
          >
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('depots')"
              *ngIf="permissions['Add New Depot']?.add || Users?.actor == 1"
            >
              <a routerLink="/admin/depots/add-depot">
                <span>Add New Depot </span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('depots')"
              *ngIf="permissions['Manage Depots']?.index || Users?.actor == 1"
            >
              <a routerLink="/admin/depots/existing-depot">
                <span>Existing Depots</span>
              </a>
            </li>
          </ul>
        </li>

        <li
          routerLinkActive="active"
          *ngIf="permissions['Manage Promo Code']?.index || Users?.actor == 1"
        >
          <a routerLink="/admin/promocode">
            <i class="icon dripicons-ticket"></i>
            <span>Manage Promo code </span>
          </a>
        </li>

        <li
          routerLinkActive="active"
          *ngIf="permissions['Transportation Rate']?.index || Users?.actor == 1"
        >
          <a
            routerLink="/admin/transportation"
            *ngIf="
              permissions['Transportation Rate']?.index || Users?.actor == 1
            "
          >
            <i class="icon dripicons-help"></i>
            <span
              >Manage Transportation <br />
              <span class="pl-34">Rates</span>
            </span>
          </a>
        </li>

        <li
          routerLinkActive="active"
          *ngIf="permissions['Manage States']?.index || Users?.actor == 1"
        >
          <a routerLink="/admin/states">
            <i class="icon dripicons-user-group"></i>
            <span>Manage States </span>
          </a>
        </li>

        <li
          routerLinkActive="active"
          *ngIf="permissions['Notifications']?.index || Users?.actor == 1"
        >
          <a routerLink="/admin/notifications">
            <i class="icon dripicons-bell"></i>
            <span>Notifications</span>
          </a>
        </li>

        <li
          routerLinkActive="active"
          *ngIf="
            permissions['Order History']?.index ||
            permissions['Stock Reports']?.index ||
            permissions['Sales Report']?.index ||
            permissions['Loading Report']?.index ||
            permissions['Trips History']?.index ||
            Users?.actor == 1
          "
        >
          <a routerLink="/admin/reports">
            <i class="icon dripicons-graph-bar"></i>
            <span>Reports </span>
          </a>
        </li>

        <li
          class="nav-dropdown"
          [className]="
            isActivated == 'profile' ||
            isActivated == 'settings' ||
            isActivated == 'roles' ||
            isActivated == 'faq' ||
            isActivated == 'faq' ||
            isActivated == 'bank'
              ? 'nav-dropdown active'
              : 'nav-dropdown'
          "
        >
          <a
            class="has-arrow"
            (click)="collapseSidebar('settings')"
            [attr.aria-expanded]="
              isActivated == 'profile' ||
              isActivated == 'settings' ||
              isActivated == 'roles' ||
              isActivated == 'faq' ||
              isActivated == 'faq' ||
              isActivated == 'bank'
                ? true
                : false
            "
            aria-controls="collapseExample"
          >
            <i class="icon dripicons-gear"></i>
            <span>Settings</span>
          </a>
          <ul
            class="nav-sub"
            [ngbCollapse]="
              isActivated == 'profile' ||
              isActivated == 'settings' ||
              isActivated == 'roles' ||
              isActivated == 'faq' ||
              isActivated == 'faq' ||
              isActivated == 'bank'
                ? false
                : true
            "
            [attr.aria-expanded]="
              isActivated == 'profile' ||
              isActivated == 'settings' ||
              isActivated == 'roles' ||
              isActivated == 'faq' ||
              isActivated == 'faq' ||
              isActivated == 'bank'
                ? true
                : false
            "
          >
            <li routerLinkActive="active" (click)="collapseSidebar('settings')"
            *ngIf="Users?.actor == 1">
              <a routerLink="/admin/profile">
                <span>MOB Profile </span>
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="/admin/settings/threshold">
                <span>Threshold for LPG Sale</span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              (click)="collapseSidebar('bank')"
              *ngIf="permissions['Manage Banks']?.index || Users?.actor == 1"
            >
              <a routerLink="/admin/settings/banks">
                <span>My Bank Accounts</span>
              </a>
            </li>
            <li>
              <a routerLink="/admin/brands">
                <span>Truck Make</span>
              </a>
            </li>
            <!-- <li><a routerLink="/admin/model"><span>Truck Model</span></a></li> -->
            <li
              class="nav-dropdown"
              [className]="
                isActivated == 'roles' ? 'nav-dropdown active' : 'nav-dropdown'
              "
              *ngIf="
                permissions['Manage Roles']?.index ||
                permissions['Team Members']?.index ||
                Users?.actor == 1
              "
            >
              <a
                class="has-arrow"
                (click)="collapseSidebar('roles')"
                [attr.aria-expanded]="isActivated == 'roles' ? true : false"
                aria-controls="collapseExample"
              >
                <span>Roles & Permissions</span>
              </a>
              <ul
                class="nav-sub"
                [ngbCollapse]="isActivated == 'roles' ? false : true"
                [attr.aria-expanded]="isActivated == 'roles' ? true : false"
              >
                <li
                  routerLinkActive="active"
                  (click)="collapseSidebar('roles')"
                  *ngIf="
                    permissions['Manage Roles']?.index || Users?.actor == 1
                  "
                >
                  <a routerLink="/admin/settings/roles">
                    <span>Manage Roles </span>
                  </a>
                </li>
                <li
                  routerLinkActive="active"
                  (click)="collapseSidebar('roles')"
                  *ngIf="
                    permissions['Team Members']?.index || Users?.actor == 1
                  "
                >
                  <a routerLink="/admin/settings/team-members">
                    <span>Team Members</span>
                  </a>
                </li>
              </ul>
            </li>
            <li
              class="nav-dropdown"
              [className]="
                isActivated == 'faq' ? 'nav-dropdown active' : 'nav-dropdown'
              "
              *ngIf="
                permissions['Manage Driver FAQs']?.index ||
                permissions['Manage Customer FAQs']?.index ||
                Users?.actor == 1
              "
            >
              <a
                class="has-arrow"
                (click)="collapseSidebar('faq')"
                [attr.aria-expanded]="isActivated == 'faq' ? true : false"
                aria-controls="collapseExample"
              >
                <span>Manage FAQs</span>
              </a>
              <ul
                class="nav-sub"
                [ngbCollapse]="isActivated == 'faq' ? false : true"
                [attr.aria-expanded]="isActivated == 'faq' ? true : false"
              >
                <li
                  routerLinkActive="active"
                  (click)="collapseSidebar('faq')"
                  *ngIf="
                    permissions['Manage Customer FAQs']?.index ||
                    Users?.actor == 1
                  "
                >
                  <a routerLink="/admin/settings/faq/customers">
                    <span>Customers </span>
                  </a>
                </li>
                <li
                  routerLinkActive="active"
                  (click)="collapseSidebar('faq')"
                  *ngIf="
                    permissions['Manage Driver FAQs']?.index ||
                    Users?.actor == 1
                  "
                >
                  <a routerLink="/admin/settings/faq/drivers">
                    <span>Drivers</span>
                  </a>
                </li>
              </ul>
            </li>
            <li
              routerLinkActive="active"
              *ngIf="permissions['CMS']?.index || Users?.actor == 1"
            >
              <a routerLink="/admin/settings/cms">
                <span>Content Management</span>
              </a>
            </li>
            <li
              routerLinkActive="active"
              *ngIf="permissions['Email Template']?.index || Users?.actor == 1"
            >
              <a routerLink="/admin/settings/emailtemplate">
                <span>Email Template</span>
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="/admin/settings/general-settings">
                <span>General Settings</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</aside>
