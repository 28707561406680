import { Pipe, PipeTransform } from "@angular/core";
import { DeliveryOrderStatus } from "../constants";

@Pipe({
  name: "deliveryOrderStatusColors",
})
export class DeliveryOrderStatusColorsPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case DeliveryOrderStatus.AwaitingPaymentConfirmation:
        return "label-danger";
        break;
      case DeliveryOrderStatus.PaymentConfirmed:
        return "label-info";
        break;
      case DeliveryOrderStatus.OrderAssigned:
        return "label-blue";
        break;
      case DeliveryOrderStatus.OutForDelivery:
        return "label-light-green";
        break;
      case DeliveryOrderStatus.ArrivedDestination:
        return "label-light-green";
        break;

      case DeliveryOrderStatus.CompleteDelivery:
        return "label-success";
        break;

      case DeliveryOrderStatus.Cancelled:
        return "label-gray";
        break;
      case DeliveryOrderStatus.Refunded:
        return "label-success";
        break;
    }
    return null;
  }
}
