import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private apiService: ApiService) {}

  login(data): Observable<any> {
    return this.apiService.post("/auth/login", data);
  }

  getProfile(): Observable<any> {
    return this.apiService.get("/auth/getProfile");
  }

  editProfile(data): Observable<any> {
    return this.apiService.formDataPost("/auth/editAdminProfile", data);
  }
  
  editMemberProfile(data): Observable<any> {
    return this.apiService.formDataPost("/auth/editMemberProfile", data);
  }
  changePassword(data): Observable<any> {
    return this.apiService.post("/auth/changePassword", data);
  }

  forgotPassword(data): Observable<any> {
    return this.apiService.post("/auth/forgotPassword", data);
  }

  resetPassword(data): Observable<any> {
    return this.apiService.post("/auth/resetPassword", data);
  }

  logout() {
    return this.apiService.get("/auth/logout");
  }

  verifyEmail(token): Observable<any> {
    return this.apiService.get(`/auth/verifyEmail/${token}`);
  }
}
