import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { CoreService, staticAlert } from "src/app/shared/services/core.service";
import { AuthService } from "src/app/shared/services/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent implements OnInit {
  email = new FormControl("", [Validators.required, Validators.email]);
  actor = new FormControl(1, []);
  fotgotPasswordForm = new FormGroup({
    email: this.email,
    actor:this.actor
  });
  constructor(
    private coreService: CoreService,
    private authService: AuthService
  ) {}

  onSubmit() {
    if (this.fotgotPasswordForm.valid) {
      this.authService
        .forgotPassword({ email: this.email.value,actor:this.actor.value })
        .subscribe((resp) => {
          if (resp.success == 1) {
            const message: staticAlert = {
              message: resp.data.message,
              type: "success",
            };
            this.coreService.showMessge(message);
            this.coreService.navigateTo("/login");
          } });
    }
  }
  ngOnInit() {}
}
